import { colors } from 'constants/colors'
import styled from 'styled-components'

export const Avatar = (props: {
  ticketNumber: number
  size: number
  circleColor?: string
}) => {
  const noOfImages = 47

  const url = `https://firebasestorage.googleapis.com/v0/b/queue-375211.appspot.com/o/Seebad_Enge_${String(
    (props.ticketNumber % noOfImages) + 1,
  ).padStart(3, '0')}.png?alt=media`

  return (
    <Wrapper style={{ width: props.size, height: props.size }}>
      <Image src={url} alt={`${props.ticketNumber}`} />
      <Circle
        style={{
          width: props.size / 2.2,
          height: props.size / 2.2,
          borderColor: props.circleColor,
        }}
      >
        <h1 style={{ fontSize: props.size / 4.5 }}>{props.ticketNumber}</h1>
      </Circle>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding: 16px;
  position: relative;

  animation: appear;
  animation-duration: 3.5s;
  animation-fill-mode: forwards;

  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`

const Image = styled.img`
  object-fit: contain;
  object-position: bottom;
  width: 100%;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: white;
  border: 6px solid ${colors.green};
`
