import { Avatar } from 'components/Avatar'
import { colors } from 'constants/colors'
import { format } from 'date-fns'
import { ApiService, TicketSchema } from 'openapi'
import { useEffect, useState } from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useGetDayData, useGetTicket, useInvalidateTicket } from './mutations'

const checkTimeDiff = (ticket: TicketSchema) => {
  const now = new Date().getTime()
  const timeDiff = now - ticket.entryTime!
  const timeLeft = 5 * 60 * 1000 - timeDiff
  return Math.ceil(timeLeft / 1000 / 60)
}

const checkTicketValid = (ticket: TicketSchema) => {
  if (!ticket.entryTime) {
    return true
  }
  return checkTimeDiff(ticket) > 0 ? true : false
}

export const UserPageWrapper = () => {
  // Check if day id is in params, it can also be the incorrect day id or any other string
  const { dayId } = useParams<{
    dayId: string
  }>()

  // todays ticket identifier
  const date = format(new Date(), 'yyyy-MM-dd')
  const dataKey = `ticket:${date}`

  // if localstorage already has a ticket with todays identifier get it
  const ticketId = localStorage.getItem(dataKey)

  if (ticketId) {
    // this ticket id has todays identifier but it could be that its not valid anymore or an entirely wrong id
    return <CheckLocalTicket dataKey={dataKey} ticketId={ticketId} />
  }

  if (dayId) {
    return <CreateTicket dataKey={dataKey} dayId={dayId} />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: '0px 16px',
      }}
    >
      <h3 style={{ color: colors.red }}>Kein gültiges Ticket.</h3>
      <h4>Bitte scanne den heutigen QR Code im Seebad Enge.</h4>
    </div>
  )
}

export const CheckLocalTicket = (props: {
  ticketId: string
  dataKey: string
}) => {
  const { data: ticket, isLoading } = useQuery(
    props.dataKey,
    () => ApiService.getTicket(props.ticketId),
    {
      refetchOnWindowFocus: true,
    },
  )

  if (isLoading) {
    return <></>
  }

  if (ticket && ticket.valid) {
    if (!checkTicketValid(ticket)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: '0px 16px',
          }}
        >
          <h3 style={{ color: colors.red }}>Ticket abgelaufen.</h3>
          <Button
            onClick={() => {
              localStorage.removeItem(props.dataKey)
              window.location.reload()
            }}
          >
            <h4>Erneut scannen</h4>
          </Button>
        </div>
      )
    }

    return <UserPage ticket={ticket} dataKey={props.dataKey} />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: '0px 16px',
      }}
    >
      <h3 style={{ color: colors.red }}>Kein gültiges Ticket.</h3>
      <Button
        onClick={() => {
          localStorage.removeItem(props.dataKey)
          window.location.reload()
        }}
      >
        <h4>Erneut scannen</h4>
      </Button>
    </div>
  )
}

export const CreateTicket = (props: { dataKey: string; dayId: string }) => {
  const navigate = useNavigate()
  const { data: ticket, isLoading } = useQuery(
    props.dataKey,
    () => ApiService.addTicket({ manual: false, dayId: props.dayId }),
    {
      refetchOnWindowFocus: true,
      // dayId is valid and ticket created
      onSuccess: (data) => {
        localStorage.setItem(props.dataKey, data._id)
        navigate('/')
      },
    },
  )

  if (isLoading) {
    return <></>
  }

  if (ticket && ticket.valid) {
    return <UserPage ticket={ticket} dataKey={props.dataKey} />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: '0px 16px',
      }}
    >
      <h3 style={{ color: colors.red }}>Kein gültiges Ticket.</h3>
      <h4>Bitte scanne den heutigen QR Code im Seebad Enge.</h4>
    </div>
  )
}

export const UserPage = (props: {
  ticket: TicketSchema & { position: number }
  dataKey: string
}) => {
  const navigate = useNavigate()
  const { ticket } = props
  const getTicket = useGetTicket()
  const invalidateTicket = useInvalidateTicket()

  const [refreshed, setRefreshed] = useState(false)

  const [signOff, setSignOff] = useState(false)

  useEffect(() => {
    if (refreshed) {
      setTimeout(() => setRefreshed(false), 5000)
    }
  }, [refreshed])

  return (
    <Card
      style={{
        background:
          ticket.position === 0 ? colors.greenTransparent : 'transparent',
      }}
    >
      {signOff && (
        <PopOver>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <h3>Aus Warteliste austragen?</h3>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <Button
              onClick={() => {
                invalidateTicket.mutate(ticket._id)
                setSignOff(false)
                navigate('/signoff')
              }}
            >
              <h4 style={{ color: colors.red }}>Austragen</h4>
            </Button>
            <Button onClick={() => setSignOff(false)}>
              <h4>Abbrechen</h4>
            </Button>
          </div>
        </PopOver>
      )}
      <Section style={{ flex: 2 }}>
        <h3>Dein Ticket</h3>
        <Avatar
          ticketNumber={ticket.number}
          size={200}
          circleColor={colors.textColor}
        />
      </Section>
      <Section style={{ flex: 1 }}>
        {ticket.position > 0 ? (
          <h3>
            <span style={{ fontSize: 38, color: colors.red }}>
              {ticket.position}
            </span>{' '}
            {ticket.position === 1 ? 'Person' : 'Personen'} vor dir
          </h3>
        ) : (
          <>
            <h2 style={{ textAlign: 'center' }}>Du bist an der Reihe!</h2>
            <h4 style={{ textAlign: 'center' }}>
              Zeig Dein Ticket an der Kasse, es ist gültig für die{' '}
              {checkTimeDiff(ticket) === 1
                ? 'nächste Minute'
                : `nächsten ${checkTimeDiff(ticket)} Minuten`}
            </h4>
          </>
        )}
      </Section>
      <Section style={{ flex: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
          <Button
            onClick={() => {
              setRefreshed(true)
              getTicket.mutate(ticket._id)
            }}
          >
            {!refreshed ? (
              <>
                <h4 style={{ marginRight: 16 }}>Aktualisieren</h4>
                <FiRefreshCcw color={colors.textColor} size={26} />
              </>
            ) : (
              <h4 style={{ marginRight: 16, color: colors.green }}>
                Aktualisiert
              </h4>
            )}
          </Button>
          <Button onClick={() => setSignOff(true)}>
            <h4 style={{ marginRight: 16, color: colors.red }}>Austragen</h4>
          </Button>
        </div>
      </Section>
      {/* <Section>
        <a
          href="https://www.google.ch"
          target="_blank"
          rel="noopener noreferrer"
          style={{ fontSize: 24 }}
        >
          FAQs
        </a>
      </Section> */}
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px;
`

const PopOver = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 5000;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  padding: 10%;
`

const Button = styled.button`
  display: flex;
  height: 56px;
  border-radius: 0;
  border: 1px solid grey;
  width: 260px;
  padding: 16px;
  max-width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  :hover {
    opacity: 0.8;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`
