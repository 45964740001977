/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTicketBody } from '../models/AddTicketBody';
import type { AddTicketResponse } from '../models/AddTicketResponse';
import type { CheckApiKeyBody } from '../models/CheckApiKeyBody';
import type { DaySchema } from '../models/DaySchema';
import type { GetDayDataReponse } from '../models/GetDayDataReponse';
import type { GetTicketResponse } from '../models/GetTicketResponse';
import type { TicketSchema } from '../models/TicketSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * Get ticket
     * @param ticketId
     * @returns GetTicketResponse Ok
     * @throws ApiError
     */
    public static getTicket(
        ticketId: string,
    ): CancelablePromise<GetTicketResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/ticket/get/{ticketId}',
            path: {
                'ticketId': ticketId,
            },
        });
    }

    /**
     * Add ticket
     * @param requestBody
     * @returns AddTicketResponse Ok
     * @throws ApiError
     */
    public static addTicket(
        requestBody: AddTicketBody,
    ): CancelablePromise<AddTicketResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/ticket/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get Day Data
     * @param date
     * @returns GetDayDataReponse Ok
     * @throws ApiError
     */
    public static dayData(
        date: string,
    ): CancelablePromise<GetDayDataReponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/day/data/{date}',
            path: {
                'date': date,
            },
        });
    }

    /**
     * Next ticket
     * @param dayId
     * @returns TicketSchema Ok
     * @throws ApiError
     */
    public static nextTicket(
        dayId: string,
    ): CancelablePromise<TicketSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/ticket/next/{dayId}',
            path: {
                'dayId': dayId,
            },
        });
    }

    /**
     * Toggle day
     * @param dayId
     * @returns DaySchema Ok
     * @throws ApiError
     */
    public static toggleDay(
        dayId: string,
    ): CancelablePromise<DaySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/day/toggle/{dayId}',
            path: {
                'dayId': dayId,
            },
        });
    }

    /**
     * Get crowd
     * @returns number Ok
     * @throws ApiError
     */
    public static getCrowd(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/crowd',
        });
    }

    /**
     * Get server time
     * @returns number Ok
     * @throws ApiError
     */
    public static getServerTime(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/servertime',
        });
    }

    /**
     * Add ticket
     * @param requestBody
     * @returns boolean Ok
     * @throws ApiError
     */
    public static checkApiKey(
        requestBody: CheckApiKeyBody,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/checkApiKey',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Invalidate ticket
     * @param requestBody
     * @returns TicketSchema Ok
     * @throws ApiError
     */
    public static invalidateTicket(
        requestBody: {
            ticketId: string;
        },
    ): CancelablePromise<TicketSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/ticket/invalidate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
