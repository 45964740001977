export const colors = {
  green: '#008037',
  red: '#B5240B',
  lightGrey: '#cccccc',
  textColor: '#585555',
  textColorBright: '#eaeaea',
  redTransparent: '#ffa8a85e',
  greenTransparent: '#69ff4280',
  greenVeryTransparent: '#69ff421A',
}
