import { ToggleWaiting } from 'components/ToggleWaiting'
import { colors } from 'constants/colors'
import { format } from 'date-fns'
import { ApiService } from 'openapi'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useAddTicket, useNextTicket, useToggleDay } from './mutations'

export const AdminPanel = () => {
  const addTicket = useAddTicket()
  const nextTicket = useNextTicket()
  const toggleDay = useToggleDay()
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const date = format(new Date(), 'yyyy-MM-dd')

  const { data: dayData, isLoading } = useQuery(
    `day:${date}`,
    () => ApiService.dayData(date),
    {
      refetchInterval: 5 * 1000,
    },
  )

  if (!dayData || isLoading) {
    return <></>
  }

  return (
    <Wrapper>
      <Card
        style={{
          justifyContent: 'flex-start',
          gap: 16,
          flexWrap: 'wrap',
        }}
      >
        <h1>Einlass</h1>
        {dayData.entryList.map((v) => (
          <NumberWrapper key={v.number}>
            <h2>{v.number}</h2>
          </NumberWrapper>
        ))}
      </Card>
      <Card style={{ borderLeft: '4px solid grey' }}>
        <ToggleWaiting
          waitingListActive={dayData.day.waitingListActive}
          onClick={() => {
            dayData.waitList.length === 0 || !dayData.day.waitingListActive
              ? toggleDay.mutate(dayData.day._id)
              : toast.error(`Leute in Warteschlange`)
          }}
        />
        <Card>
          <h1>Länge Warteliste</h1>
          <h1 style={{ fontSize: 72 }}>
            {dayData.day.waitingListActive ? dayData.waitList.length : '--'}
          </h1>
        </Card>
        <Card>
          <Button
            style={{ background: colors.textColor }}
            onClick={() => {
              nextTicket.mutate(dayData.day._id)
            }}
            disabled={
              dayData.waitList.length === 0 || !dayData.day.waitingListActive
            }
          >
            <h3 style={{ color: 'white' }}>Nächste Person</h3>
          </Button>
          <Button
            onClick={() => {
              setButtonDisabled(true)
              addTicket.mutate(
                {
                  dayId: dayData.day._id,
                  manual: true,
                },
                {
                  onSuccess: (v) => {
                    setButtonDisabled(false)
                    toast.success(`Ticket #${v.number} erstellt`)
                  },
                  onError: (v) => {
                    setButtonDisabled(false)
                    toast.error(`Ticket nicht erstellt`)
                  },
                },
              )
            }}
            disabled={buttonDisabled || !dayData.day.waitingListActive}
          >
            <h3>Ticket manuell erstellen</h3>
          </Button>
        </Card>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 64px;
  overflow: hidden;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 24px;
`

const Button = styled.button`
  display: flex;
  flex: 0 0 120px;
  width: 400px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    cursor: default;
    opacity: 0.3;
  }
`

const NumberWrapper = styled.div`
  display: flex;
  width: 160px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background-color: white;
`
