import { AdminPanel } from 'pages/AdminPanel'
import { createGlobalStyle } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { OpenAPI } from 'openapi'
import { UserPage, UserPageWrapper } from 'pages/UserPage'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Scaffold } from 'Scaffold'
import { Monitor } from 'pages/Monitor'
import { TestCrowd } from 'pages/TestCrowd'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthWrapper } from 'AuthWrapper'
import { SignOff } from 'pages/SignOff'

export const GlobalStyle = createGlobalStyle`
  html {
    margin: 0;
    padding: 0;
  }

  #root {
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
  }

  
  * {
    box-sizing: border-box;
  }
  
  h1,h2,h3,h4,h5,p,a,input {
    font-family: Helvetica, Arial sans-serif;
    margin: 0;
    padding: 0;
    color: #464646;
  }

  input {
    font-size: 48px;
    text-align: center;
  }
  
  h1 {
    font-size: 48px;
    text-align: center;
  }

  h2 {
    font-size: 40px;
    text-align: center;
  }

  h3 {
    font-size: 28px;
    text-align: center;

  }

  h4 {
    font-size: 22px;
    text-align: center;

  }

  .toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  color: #10171D; /* #10171D */
  font-size: 32px !important;
  }
`

const getHeaders = async () => {
  const apiKey = localStorage.getItem('apiKey')
  return {
    Authentication: apiKey || '',
  }
}

OpenAPI.HEADERS = getHeaders

OpenAPI.BASE = process.env.REACT_APP_APP_SERVER || ''

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: '/',
    element: <UserPageWrapper />,
  },
  {
    path: '/signoff',
    element: <SignOff />,
  },
  {
    path: '/monitor',
    element: (
      <AuthWrapper>
        <Monitor />
      </AuthWrapper>
    ),
  },
  {
    path: '/admin',
    element: (
      <AuthWrapper>
        <AdminPanel />
      </AuthWrapper>
    ),
  },
  {
    path: '/:dayId',
    element: <UserPageWrapper />,
  },
  {
    path: '/crowd',
    element: <TestCrowd />,
  },
  {
    path: '*',
    element: <UserPageWrapper />,
  },
])

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <Scaffold>
        <ToastContainer
          position="top-right"
          autoClose={30 * 1000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          bodyClassName="toastBody"
        />
        <RouterProvider router={router} />
      </Scaffold>
    </QueryClientProvider>
  )
}
