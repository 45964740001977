import styled from 'styled-components'

export const SignOff = () => {
  return (
    <Wrapper>
      <Card>
        <h3>Bis zum nächsten mal!</h3>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 64px;
  overflow: hidden;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 24px;
`
