import { colors } from 'constants/colors'
import styled from 'styled-components'

export const ToggleWaiting = (props: {
  waitingListActive?: boolean
  onClick: () => void
}) => {
  const { waitingListActive, onClick } = props

  return (
    <Wrapper>
      <Text
        style={{ color: colors.green, opacity: waitingListActive ? 0.2 : 1 }}
      >
        Keine Warteliste
      </Text>
      <Toggle onClick={onClick}>
        <ToggleCircle
          style={{
            marginLeft: waitingListActive ? 54 : 0,
          }}
        />
      </Toggle>
      <Text style={{ color: colors.red, opacity: waitingListActive ? 1 : 0.2 }}>
        Warteliste aktiv
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const Toggle = styled.div`
  display: flex;
  width: 120px;
  height: 64px;
  border-radius: 40px;
  background-color: ${colors.lightGrey};
  align-items: center;
  padding: 8px;
  cursor: pointer;
`

const ToggleCircle = styled.div`
  display: flex;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: white;
  transition: all 0.5s;
`

const Text = styled.h2`
  text-align: center;
  width: 200px;
`
