import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from 'react-icons/bs'
import useSound from 'use-sound'

export const Scaffold = (props: { children: ReactNode }) => {
  useEffect(() => {
    const timeout = setInterval(() => {
      setHour(new Date().getHours())
    }, 60 * 1000)

    return () => {
      clearInterval(timeout)
    }
  }, [])

  const [hour, setHour] = useState(new Date().getHours())

  if (hour >= 23 || hour < 8) {
    return <Wrapper style={{ backgroundColor: 'black' }}></Wrapper>
  }
  return (
    <Wrapper>
      <Image src={'bg_default.jpg'} />
      <Content>{props.children} </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  z-index: 10;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2%;
  background: rgba(255, 255, 255, 0.7);
`

const Image = styled.img`
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
`
