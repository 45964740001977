import { ApiService } from 'openapi'
import { useQuery } from 'react-query'

export const TestCrowd = () => {
  const { data: crowdNumber } = useQuery('crowd', () => ApiService.getCrowd())

  const { data: serverTime } = useQuery('serverTime', () =>
    ApiService.getServerTime(),
  )

  return (
    <p>
      Testing crowd {crowdNumber}, testing server time{' '}
      {new Date(serverTime || 0).toJSON()}
    </p>
  )
}
