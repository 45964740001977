import { format } from 'date-fns'
import {
  AddTicketBody,
  ApiService,
  DaySchema,
  GetDayDataReponse,
  GetTicketResponse,
  TicketSchema,
} from 'openapi'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

export const useToggleDay = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: string) => ApiService.toggleDay(body),
    onMutate: () => toast.info(`Umschalten...`, { autoClose: 2000 }),
    onSuccess: (day) => {
      toast.success(
        `Warteliste ${
          day.waitingListActive ? 'eingeschaltet' : 'ausgeschaltet'
        }`,
        { autoClose: 2000 },
      )
      const date = format(new Date(), 'yyyy-MM-dd')
      queryClient.setQueryData<GetDayDataReponse | undefined>(
        `day:${date}`,
        (d) => {
          if (!d) {
            return undefined
          } else {
            return {
              ...d,
              day,
            }
          }
        },
      )
    },
    onError: () => {},
  })
}

export const useNextTicket = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: string) => ApiService.nextTicket(body),
    onMutate: () =>
      toast.info(`Nächste Person hereingerufen`, { autoClose: 2000 }),
    onSuccess: (ticket) => {
      const date = format(new Date(), 'yyyy-MM-dd')

      queryClient.setQueryData<GetDayDataReponse | undefined>(
        `day:${date}`,
        (d) => {
          if (!d) {
            return undefined
          } else {
            return {
              ...d,
              day: {
                ...d.day,
                currentTicket: ticket.number,
              },
              waitList: d.waitList.filter((n) => n.number > ticket.number),
              entryList: [...d.entryList, ticket],
            }
          }
        },
      )
    },
    onError: () => {},
  })
}

export const useAddTicket = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: AddTicketBody) => ApiService.addTicket(body),
    onMutate: (body: AddTicketBody) =>
      body.manual && toast.info(`Ticket wird erstellt...`, { autoClose: 2000 }),
    onSuccess: (data, body) => {
      const date = format(new Date(), 'yyyy-MM-dd')

      if (!body.manual) {
        localStorage.setItem(`ticket:${date}`, data._id)
        queryClient.setQueryData<TicketSchema | undefined>(
          `ticket:${date}`,
          () => data,
        )
      } else {
        queryClient.setQueryData<GetDayDataReponse | undefined>(
          `day:${date}`,
          (d) => {
            return d
              ? {
                  ...d,
                  day: { ...d.day, latestTicket: data.number },
                  waitList: [...d.waitList, data],
                }
              : undefined
          },
        )
      }
    },
    onError: () => {},
  })
}

export const useGetDayData = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (date: string) => ApiService.dayData(date),
    onSuccess: (data, date) => {
      queryClient.setQueryData<GetDayDataReponse | undefined>(
        `day:${date}`,
        () => data,
      )
    },
    onError: () => {},
  })
}

export const useGetTicket = () => {
  const queryClient = useQueryClient()

  const date = format(new Date(), 'yyyy-MM-dd')
  const dataKey = `ticket:${date}`

  return useMutation({
    mutationFn: (ticketId: string) => ApiService.getTicket(ticketId),
    onSuccess: (data) => {
      queryClient.setQueryData<GetTicketResponse | undefined>(
        dataKey,
        () => data,
      )
    },
    onError: () => {},
  })
}

export const useInvalidateTicket = () => {
  const queryClient = useQueryClient()

  const date = format(new Date(), 'yyyy-MM-dd')
  const dataKey = `ticket:${date}`

  return useMutation({
    mutationFn: (ticketId: string) => ApiService.invalidateTicket({ ticketId }),
    onSuccess: (res) => {
      queryClient.removeQueries(dataKey)
      localStorage.removeItem(dataKey)
      queryClient.setQueryData<
        (GetDayDataReponse & { ticketId: string }) | undefined
      >(`day:${date}`, (v) => {
        if (v) {
          return {
            ...v,
            waitList: v.waitList.filter((item) => item._id !== res._id),
            entryList: v.entryList.filter((item) => item._id !== res._id),
          }
        }
        return undefined
      })
    },
    onError: () => {},
  })
}
