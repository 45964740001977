import { ApiService } from 'openapi'
import { ReactNode, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const AuthWrapper = (props: { children: ReactNode }) => {
  const [password, setPassword] = useState<string | undefined>()

  const { data: validKey, isLoading } = useQuery('authenticated', () => {
    const apiKey = localStorage.getItem('apiKey')

    if (apiKey) {
      return ApiService.checkApiKey({ apiKey })
    } else {
      return false
    }
  })

  if (isLoading) {
    return <></>
  }

  if (!validKey) {
    return (
      <form style={{ display: 'flex', justifyContent: 'center' }}>
        <input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
        ></input>
        <Button
          onClick={() => {
            localStorage.setItem('apiKey', password || '')
          }}
        >
          <h1>Submit</h1>
        </Button>
      </form>
    )
  }

  return <>{props.children}</>
}

const Button = styled.button`
  display: flex;
  height: 120px;
  width: 400px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    cursor: default;
    opacity: 0.3;
  }
`
