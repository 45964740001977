import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'

export const QrCode = (props: { size: number; url: string }) => {
  return (
    <Wrapper>
      <QRCodeSVG size={props.size} value={props.url} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 24px;
  background-color: white;
`
